<template>
  <tippy
    ref="tippyRef"
    interactive
    :arrow="false"
    :placement="placement"
    trigger="click"
    class="select-wrapper inline-block"
    :class="props.disabled ? ['pointer-events-none', 'cursor-not-allowed'] : []"
    theme="dropdown"
    :offset="[0, 0]"
    maxWidth="none"
    :onHide="handleHide"
    :onShow="handleShow"
  >
    <div
      :class="
        props.disabled
          ? ['cursor-not-allowed', 'bg-white/20', props.wrapClass]
          : [
              props.wrapClass,
              'hover:bg-white/[.15]',
              'focus-within:border-white/80',
              'focus:border-white/80',
              'focus:outline-none',
              'focus:bg-white/[.15]',
              'focus-within:bg-white/[.15]',
              'focus-within:border',
              'focus:border',
              'focus:rounded-b-none',
              'focus-within:rounded-b-none',
              'h-full',
            ]
      "
      class="box-border flex items-center w-full ps-6 pe-4 py-[11px] rounded border border-transparent bg-white/10"
      @click="handleClick"
    >
      <UiObserverImage
        v-if="props.countryIcon"
        class="inline-block flex-shrink-0 me-4"
        :lazyImg="`/static/jpg/flags-jpg/${
          props.selectedOption
            ? props.selectedOption.isoNation.toLowerCase()
            : 'default-flag'
        }.jpg`"
        immediately
      >
      </UiObserverImage>
      <input
        v-model="query"
        type="text"
        ref="inputRef"
        class="country-input outline-none bg-transparent w-full truncate text-white opacity-[0.87] font-Abu text-button-big"
        :class="{
          'placeholder-white': props.selectedOption,
          'placeholder:text-white opacity-50': props.disabled,
          'plain-text-holder': isRTL,
        }"
        :placeholder="selectedLabel"
        @click.stop="handleInputClick"
        @input="onInput"
        @keydown.enter="handleInputEnter"
      />
      <div class="flex items-center w-6">
        <Icon
          v-if="isOpened"
          :class="props.disabled ? 'text-white opacity-50' : ''"
          name="IconChevronUp"
          size="18"
          class="cursor-pointer outline-none text-white"
          tabindex="0"
        ></Icon>
        <Icon
          v-else
          :class="props.disabled ? 'text-white opacity-50' : ''"
          name="IconChevronDown"
          size="18"
          class="cursor-pointer outline-none text-white"
          tabindex="0"
        ></Icon>
      </div>
    </div>
    <template #content>
      <!-- firefox backdrop -->
      <div
        class="scroll-box relative overflow-y-auto scrollbar-hide max-h-52 w-full bg-[#6c4c4c]"
        :class="scrollGradientClass"
        @scroll="throttleHandleScroll"
        ref="scrollBoxRef"
      >
        <div v-if="filteredOptions.length === 0">
          <slot name="onEmpty">
            <div class="flex items-center w-full h-20 py-4 px-4 relative">
              <Icon
                class="me-4 flex-shrink-0 relative self-start"
                name="mingcute:warning-line"
                size="24"
              ></Icon>
              <div class="text-base leading-normal break-words">
                {{ $t("nonExistCountry") }}
              </div>
            </div>
          </slot>
        </div>
        <div
          class="box-gradient-top h-8 w-full fixed top-0 pointer-events-none"
          v-if="
            scrollGradientClass === 'gradient-top' ||
            scrollGradientClass === 'gradient-top-bottom'
          "
        ></div>
        <div
          v-for="option in filteredOptions"
          :key="option.isoNation"
          class="select-option flex items-center cursor-pointer px-6 py-3 p-2 last:mb-2"
          :class="{
            'select-option--selected':
              option[props.labelKey] ===
              (props.selectedOption && props.selectedOption[props.labelKey]),
          }"
          @click.stop="handleOptionSelect(option)"
        >
          <div class="flex-1 flex items-center truncate w-full">
            <UiObserverImage
              :alt="option.isoNation"
              :lazyImg="`/static/jpg/flags-jpg/${option.isoNation?.toLowerCase()}.jpg`"
              class="me-4 flex-shrink-0"
            ></UiObserverImage>
            <div class="flex justify-between truncate w-full">
              <div class="truncate">
                {{ option[props.labelKey] }}
              </div>
              <div
                class="ps-1"
                v-show="
                  props.countryCode &&
                  (props.selectedOption &&
                    props.selectedOption[props.labelKey]) !==
                    option[props.labelKey]
                "
                style="unicode-bidi: plaintext"
              >
                {{ option["dialCode"] }}
              </div>
            </div>
          </div>
          <Icon
            v-if="
              (props.selectedOption && props.selectedOption[props.labelKey]) ===
              option[props.labelKey]
            "
            class="item-selected-icon"
            name="ic:sharp-check"
            size="24"
          ></Icon>
        </div>
        <div
          class="box-gradient-bottom h-8 w-full fixed bottom-0 pointer-events-none"
          v-if="
            scrollGradientClass === 'gradient-bottom' ||
            scrollGradientClass === 'gradient-top-bottom'
          "
        ></div>
      </div>
    </template>
  </tippy>
</template>

<script setup>
const { t, locale } = useI18n();
const { isRTL } = useCurrentLocale();
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  labelKey: {
    type: String,
    required: true,
  },
  searchKey: {
    type: Array,
    required: true,
  },
  countryCode: {
    type: Boolean,
  },
  wrapClass: {
    type: String,
  },
  selectedOption: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  countryIcon: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(["update:selectedOption"]);
const scrollBoxRef = ref();
const inputRef = ref();
const tippyRef = ref();
const isOpened = ref();
const query = ref("");
const scrollGradientClass = ref("");
const placement = ref("bottom-start");
const selectedLabel = ref();

placement.value = locale.value === "ar" ? "bottom-end" : "bottom-start";

const filterInput = (value, filterVal) => {
  const res = value
    .toLowerCase()
    .replace(/\s+/g, "")
    .indexOf(filterVal.toLowerCase().replace(/\s+/g, ""));
  return res;
};
const filteredOptions = computed(() => {
  if (query.value === "") {
    return props.options;
  } else {
    //  sort by string match index
    const res = props.options.map((o) => {
      let matchIdx = null;
      const isMatch = props.searchKey.some((searchKeyItem) => {
        matchIdx = filterInput(o[searchKeyItem], query.value);
        return matchIdx >= 0 ? true : false;
      });
      if (isMatch) {
        return {
          ...o,
          matchIdx,
        };
      } else {
        return null;
      }
    });
    return res
      .filter((item) => {
        return item;
      })
      .sort((a, b) => {
        return a.matchIdx - b.matchIdx;
      });
  }
});
watch(
  () => props.selectedOption,
  (value) => {
    console.log("country select option:", value);
    if (value) {
      selectedLabel.value = value[props.searchKey[0]] || value[props.labelKey];
    } else {
      selectedLabel.value = t("inputPlaceholder");
    }
  },
  { immediate: true }
);
watch(
  filteredOptions,
  (newOptions) => {
    if (newOptions.length <= 4) {
      scrollGradientClass.value = "";
    } else {
      scrollGradientClass.value = "gradient-bottom";
    }
  },
  { immediate: true }
);

const onInput = () => {
  nextTick(() => {
    let scrollDom = document.querySelector(".scroll-box");
    scrollDom.scrollTop = 0;
  });
};

//  useThrottleFn(, 300);
const throttleHandleScroll = () => {
  if (scrollBoxRef.value) {
    const { scrollTop, clientHeight, scrollHeight } = scrollBoxRef.value;
    const limit = 8;
    const isMoreOnBottom = scrollTop + clientHeight >= scrollHeight - limit;
    const isMoreOnTop = scrollTop > 0;
    const isStartOnTop = scrollTop == 0;
    if (!isMoreOnBottom && isMoreOnTop) {
      scrollGradientClass.value = "gradient-top-bottom";
    } else {
      if (isMoreOnBottom) {
        scrollGradientClass.value = "gradient-bottom";
      }
      if (isStartOnTop) {
        scrollGradientClass.value = "gradient-top";
      }
    }
  }
};
const handleClick = (event) => {
  inputRef.value.focus();
};
const handleOptionSelect = (option) => {
  selectedLabel.value = option[props.searchKey[0]] || option[props.labelKey];
  query.value = "";
  emit("update:selectedOption", {
    ...option,
    isoNation: option.isoNation.toLowerCase(),
  });
  tippyRef.value.hide();
};

const handleInputClick = () => {
  tippyRef.value.show();
};

const handleInputEnter = () => {
  if (filteredOptions.value?.length === 1) {
    handleOptionSelect(filteredOptions.value[0]);
  }
};
const isInViewport = (element) => {
  const selectHeight = 210;
  const top = element.offsetTop;
  const docHeight = document.documentElement.clientHeight;
  return top < docHeight - selectHeight;
};
const handleShow = async () => {
  isOpened.value = true;
  await nextTick();
  const selectWrapper = document.querySelector(".select-wrapper");
  if (!isInViewport(selectWrapper)) {
    selectWrapper?.scrollIntoView({ block: "center", inline: "nearest" });
  }
};

const handleHide = () => {
  isOpened.value = false;
};
onMounted(() => {
  ["click"].map((eventName) => {
    window.addEventListener(eventName, () => {
      if (query.value && filteredOptions.value.length >= 1) {
        let matchItem;
        const isMatch = filteredOptions.value.some((optionItem, optionIdx) => {
          const hasMatchedItem = props.searchKey
            .map((searchKeyItem) => {
              return optionItem[searchKeyItem].toLowerCase();
            })
            .includes(query.value?.toLowerCase());
          if (hasMatchedItem) {
            matchItem = optionItem;
          }
          return hasMatchedItem;
        });
        if (isMatch) {
          handleOptionSelect(matchItem);
        } else {
          query.value = "";
        }
      } else {
        query.value = "";
      }
    });
  });
});
</script>
<style land="scss" scoped>
.select-option {
  background: var(--greys-white-10, rgba(255, 255, 255, 0.1));
  &:hover {
    background: var(--surface-surface-state-hover, rgba(255, 255, 255, 0.15));
  }
}

.box-gradient-top {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 1.31%,
    rgba(255, 255, 255, 0) 100%
  );
}
.box-gradient-bottom {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
}
</style>
