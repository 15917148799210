<template>
    <div class="relative flex flex-row items-center w-fit h-12 leading-12">
        <slot name="before"></slot>
        <input 
            style="Unicode-bidi:embed;"
            
            :class="[
                `
                w-full 
                h-full 
                border 
                placeholder:text-white opacity-[0.50] 
                bg-white/[.1] 
                focus:border 
                rounded 
                text-white opacity-[0.87] 
                text-button-big
                block 
                outline-none 
                px-6 
                py-3.5 
                box-border
              disabled:bg-white/20
                disabled:cursor-not-allowed
                font-Abu
                `,
                extraClass,
                props.fail?
                'border-brand-red-error':'border-transparent focus:border-white/[.8]'
            ]" 
            v-model="inputValue"
            :type="inputType" 
            :placeholder="placeholder" 
            autocomplete="off"
            @input="updateVal" 
            :disabled="disabled"
            v-bind="$attrs"
        >
        <slot name="after">
            <button @click="handleTogglePassword" v-if="type == 'password'" class="absolute h-full right-6 top-[0] flex justify-end items-center">
                <SvgsEye v-show="!passVisible"/>
                <SvgsEyeCross v-show="passVisible"/>
            </button>
        </slot>
        <TransitionRoot
            appear
            :show="props.fail&&props.failDesc"
            as="template"
            enter="transform transition duration-[200ms]"
            enter-from="opacity-0 traslate-[0]"
            enter-to="opacity-100 traslate-[100]"
            leave="transform duration-200 transition ease-in-out"
            leave-from="opacity-100 traslate-[100]"
            leave-to="opacity-0"
        >
            <span 
                class="
                absolute 
                bottom-[-24px] 
                inline-block 
                w-auto 
                leading-5 
                left-0 
                text-[11px] 
                h-5 
                text-brand-red-error
                "
            >
            {{failDesc}}
            </span>
        </TransitionRoot>
    </div>
  </template>
  
  <script setup>
  const emits = defineEmits(['update:modelValue','change'])
  import { TransitionRoot } from '@headlessui/vue'
  const props = defineProps({
    type: {
        type:String,
        default:() => 'text'
    },
    placeholder:{
        type:String,
        default:''
    },
    disabled:{
        type:Boolean,
        default:false
    },
    extraClass:{
        type: String,
        default: ""
    },
    fail:{
        type:Boolean,
        default:false
    },
    failDesc:{
        type:String,
        default:''
    },
    maxlength:{
        type:Number,
    },
    modelValue:{
       required:true
    },
  });
  const { extraClass,disabled,type,placeholder,fail,failDesc } = props
  const sliceInputVal = val => props.maxlength ? val?.slice(0,props.maxlength) : val
  const passVisible = ref(false)
  const inputType = ref(null)
  const inputValue = ref(null)
  const handleTogglePassword = () => {
    passVisible.value = !passVisible.value
    inputType.value = passVisible.value ? 'text' : 'password'
  }
  const updateVal = (e) => {
    const targetVal = sliceInputVal(e.target.value);
    inputValue.value = targetVal
    emits('update:modelValue', targetVal);
    emits('change',targetVal);
  };
  onMounted(() => {
    inputType.value = type
  });
  watch(() => props.modelValue,newValue => {
    const val = sliceInputVal(newValue)
    inputValue.value = val
    emits('update:modelValue',val)
  })
</script>
<style>
@keyframes slideDown {
0% {
transform: translateY(-10px);
opacity: 0;
}
100% {
transform: translateY(0);
opacity: 1;
}
}
</style>
